<template>
  <div class="course-detail">
    <div class="header">
      <div class="container-header">
        <div class="course-item">
          <div class="course-content">
            <div class="course-img">
              <img :src="courseInfo.logo" alt="" style="width:240px;height:170px;">
            </div>
            <div class="course-content-item">
              <div class="course-name">
                {{courseInfo.name}}
              </div>
              <div class="course-descr">
                {{courseInfo.descr}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程列表 -->
    <div class="container">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' + coursePage}">课程中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: $route.query.mycourse ? '/classList?id='+$route.query.courseId : '/courseDetail?id='+$route.query.courseId }">班级列表</el-breadcrumb-item>
        <el-breadcrumb-item>课程目录</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="row-name">
        课程目录
      </div>
      <div class="line"></div>
      <div class="chapter-list">
        <div class="chapter-item" v-for="(item,index) in courseChapterList" :key="index">
          <Chapter :chapterInfo="item" :marginPx="0" :classId="Number(id)"></Chapter>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import { getCourseInfo,getCourseDetail } from '@/api/course'
import Chapter from '../../components/chapter.vue'
export default {
  data(){
    return {
      classType:'',
      id:0,
      courseInfo:{},
      courseChapterList:[],
      coursePage: ""
    }
  },
  components:{
    Chapter
  },
  created() {
    this.coursePage = localStorage.getItem('coursePage')
    this.id = this.$route.query.id
    this.getCourseDetail(this.$route.query.courseId)
    this.getCourseInfo(this.id)  
  },
  methods:{
    //查询课程内容详情
    getCourseInfo(id) {
      getCourseInfo(id).then(res => {
        if(res.code == 200) {
          this.courseChapterList = res.data
        }
      })
    },
    getCourseDetail(id) {
      getCourseDetail({id:id}).then(res => {
        this.courseInfo = res.data
      })
    }
    
  }
}
</script>

<style lang='scss' scoped>
.el-pagination{
  text-align: center;
}
.course-detail{
  .row-name{
    margin-top: 30px;
    font-size: 20px;
    font-weight: 400;
  }
  
  .line{
    height: 5px;
    width: 60px;
    margin: 4px 0 30px 0;
    background-color: #4394FF;
    border-radius: 5px;
  }
  .class-item{
    border-bottom: 1px solid #EEEEEE;
    padding: 20px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .header{
    width: 100%;
    background-color: #323368;
    .container-header{
      width: 1200px;
      margin: 0 auto;
      padding:60px 0 80px 0;
      display: flex;
      .course-item{
        // border: 1px solid #EEEEEE;
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
        .course-type-name{
          margin-bottom: 15px;
        }
        .course-content{
          display: flex;
          .course-content-item{
            margin-left: 20px;
            flex: 1;
            .course-name{
              margin-bottom: 13px;
              font-size: 18px;
              color: #FFFFFF;
            }
            .course-descr{
              color:#A7A7A7;
            }
          }
        }
      }
    }
  }
  .container {
    padding-top: 20px;
  }
}
</style>